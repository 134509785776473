
import axios from "axios";
import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function FormPage(props) {
    const [date, setDate] = useState('');
    const [eDate, setEdate] = useState('');
    const [desc, setDesc] = useState('');
    const [status, setStatus] = useState('To-do'); // Default status to avoid empty value
    const [statusDesc, setStatusDesc] = useState('');
    const [hours, setHours] = useState(0);
    const [data, setData] = useState([]);

    const increment = (e) => {
        e.preventDefault();
        if (hours <= 5) {
            setHours(hours + 1);
        }
    };

    const decrement = (e) => {
        e.preventDefault();
        if (hours > 0) {
            setHours(hours - 1);
        }
    };

    useEffect(() => {
        const today = new Date().toISOString().split('T')[0];
        setDate(today);
    }, []);

    const handleDateChange = (e) => {
        setDate(e.target.value);
    };

    const removeQuotesFromStrings = (jsonStr) => {
        if (jsonStr != null) {
            return jsonStr.replace(/"(.*?)"/g, (match, p1) => {
                return p1.includes(':') ? `"${p1}"` : p1;
            });
        }
        return null;
    };

    const email = removeQuotesFromStrings(localStorage.getItem('email'));
    const name = removeQuotesFromStrings(localStorage.getItem('name'));

    const handleSubmit = (e) => {
        e.preventDefault();
        const newTask = {
            name: name,
            email: email,
            date: eDate,
            task_description: desc,
            task_status: status,
            task_status_description: statusDesc,
            hours_worked: hours,
            created_on: date,
        };

        console.log('Submitting task:', newTask);

        axios.post('https://timesheet.backend.webwic.com/add_task', newTask)
            .then(res => {
                toast.success("Submission Successful");
                console.log(res.data);
                // Reset the form fields
                setEdate('');
                setDesc('');
                setStatus('To-Do'); // Reset status to default
                setStatusDesc('');
                setHours(0);
            })
            .catch(er => {
                toast.error("Submission Failed");
                console.log('Error response:', er.response);
                console.log(er);
            });
    };

    return (
        <div className="h-container">
            <Navbar />
            <div className='h-main'>
                {!props.small && <div className="h-side">
                    <Sidebar />
                </div>}
                <div className='h-d'>
                    <div className="formpage-main">
                        <div className="form-container-light">
                            <h1>Add a New Task</h1>
                            <form id="form" className="forms" onSubmit={handleSubmit}>
                                <div className="input">
                                    <label htmlFor="email">Email Address</label>
                                    <input id="email" type="email" value={email} disabled />
                                </div>
                                <div className="input-date">
                                    <div className="input">
                                        <label htmlFor="date">Date</label>
                                        <input
                                            id="date"
                                            type="date"
                                            placeholder=""
                                            value={eDate}
                                            onChange={e => setEdate(e.target.value)}
                                            required />
                                    </div>
                                    <div className="input">
                                        <label htmlFor="cdate">Created On</label>
                                        <input
                                            id="cdate"
                                            type="date"
                                            value={date}
                                            onChange={handleDateChange}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="input-counter">
                                    <label htmlFor="hours">Hours Worked</label>
                                    <div className="value">
                                        <button className="down" onClick={decrement}>-</button>
                                        <h4>{hours}</h4>
                                        <button className="up" onClick={increment}>+</button>
                                    </div>
                                </div>
                                <div className="input">
                                    <label htmlFor="tdesc">Task Description</label>
                                    <input
                                        id="tdesc"
                                        className="tdesc"
                                        type="text"
                                        value={desc}
                                        onChange={e => setDesc(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="input">
                                    <label htmlFor="tstat">Task Status</label>
                                    <select
                                        name="Task-status"
                                        id="tstat"
                                        value={status}
                                        onChange={e => setStatus(e.target.value)}
                                    >
                                        <option className="dmen" value="To-Do">To-Do</option>
                                        <option className="dmen" value="Done">Done</option>
                                        <option className="dmen" value="Doing">Doing</option>
                                    </select>
                                </div>
                                <div className="input">
                                    <label htmlFor="tstdesc">Task Status Description</label>
                                    <input
                                        id="tstdesc"
                                        className="tdesc"
                                        type="text"
                                        value={statusDesc}
                                        onChange={e => setStatusDesc(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="btn">
                                    <button className="submit" type="submit">Submit</button>
                                    <ToastContainer />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
