import React, { useEffect, useState } from "react";
import logo from "../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCircleHalfStroke, faXmark, faUser, faGear, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";
import dashboard from "../images/dashboard.png";
import details from "../images/details.png";
import operations from "../images/operations.png";

export default function Navbar(props) {
    const [small, setSmall] = useState(window.innerWidth <= 1090);
    const [sideBar, setSideBar] = useState(false);
    const navigate = useNavigate();
    const email = localStorage.getItem('email');

    useEffect(() => {
        const handleResize = () => {
            setSmall(window.innerWidth <= 1090);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function openSide(e) {
        e.preventDefault();
        setSideBar(!sideBar);
    }

    const handleLogout = () => {
        try {
            googleLogout(); // Google logout
            localStorage.clear();
            navigate('/');
        } catch (er) {
            console.log('Logout failed', er);
        }
    };

    return (
        <div className="nav-main">
            <div className="nav-container">
                {sideBar && (
                    <div className="nav-sidebar">
                        <div className="side-main">
                            <div className="side-links">
                                <ul>
                                    <div className="side-link">
                                        <img src={dashboard} alt="Dashboard" />
                                        <NavLink to='/Dashboard'><li>Dashboard</li></NavLink>
                                    </div>
                                    <div className="side-link">
                                        <img src={details} alt="Details" />
                                        <NavLink to='/TaskDetails'><li>Details</li></NavLink>
                                    </div>
                                    <div className="side-link">
                                        <img src={operations} alt="Operations" />
                                        <NavLink to='/Operations'><li>Operations</li></NavLink>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
                <div className="left">
                    {small && (
                        <FontAwesomeIcon onClick={openSide} className="bars" icon={sideBar ? faXmark : faBars} />
                    )}
                    <NavLink to='/Dashboard'><img src={logo} alt="Logo" className="logo" /></NavLink>
                </div>
                <div className="center">
                    <h1>WebWic</h1>
                </div>
                <div className="right">
                    {/* <FontAwesomeIcon className="mode" icon={faCircleHalfStroke} /> */}
                    <div className="dropdown-profile">
                        <FontAwesomeIcon className="profile" icon={faUser} />
                        <div className="dropdown">
                            <div className="link">
                                <FontAwesomeIcon className="icon" icon={faUser} />
                                <a href="#">{email}</a>
                            </div>
                            {/* <div className="link">
                                <FontAwesomeIcon className="icon" icon={faGear} />
                                <a href="">Settings</a>
                            </div> */}
                            <div className="link">
                                <FontAwesomeIcon className="icon" icon={faRightFromBracket} />
                                <a href="#" onClick={handleLogout}>Logout</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
