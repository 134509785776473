import React, { useContext } from "react";
import logo from "../images/logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { NavLink, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { User } from "../App";

export default function LoginPage() {

    const navigate = useNavigate();
    
    // Define the list of whitelisted emails
    const whitelist = [
        "ransifmanafk@gmail.com",
        "hishamabdulsalam99@gmail.com",
        "thoufeerma@gmail.com",
        "josephsajant@gmail.com",
        "sanaranjith2003@gmail.com",
        "abhinavthaj623@gmail.com",
        "amalvelyalan@gmail.com",
        "mohdziyadk@gmail.com",
        "ambedcmofficial@gmail.com",
        "ambedcm@gmail.com",
        "aiswaryashine11b@gmail.com",
        "achuthspai04@gmail.com",


    ];
    const [user, setUser] = useContext(User)

    const responseMessage = (res) => {
        console.log(res.credential);
        var userObj = jwtDecode(res.credential);

        // Check if the email is in the whitelist
        if (!whitelist.includes(userObj.email)) {
            alert("Unauthorized email. Redirecting to login page.");
            googleLogout();
            navigate('/');  // Redirect to login page
            return;
        }
        setUser(true)
        localStorage.setItem('token', JSON.stringify(res.credential));
        // store email and name in local storage 
        localStorage.setItem('email', userObj.email);
        localStorage.setItem('name', userObj.name);
        // console to check all from local storage 
        console.log(localStorage.getItem('token'));
        console.log(localStorage.getItem('email'));
        console.log(localStorage.getItem('name'));

        navigate('/Dashboard');
    };

    const errorMessage = (error) => {
        console.log(error);
    };

    const [showPassword, setShowPassword] = React.useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="main">
            <div className="logo">
                <img src={logo} alt="" />
            </div>
            <div className="f-container-light">
                <h3>Welcome back! Please Login</h3>
                <form
                    id="form"
                    autoComplete="off"
                >    
                    <div className="input">    
                        <input  
                            id="email" 
                            type="email" 
                            placeholder="Username / Email" 
                            required
                            minLength={5}
                            maxLength={20}
                        />
                        <div className="pass">
                            <input 
                                id="password" 
                                type={showPassword ? 'text' : 'password'} 
                                placeholder="Password" 
                                required
                                minLength={5}
                                maxLength={20}
                            />
                            <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                onClick={togglePasswordVisibility}
                                className="password-toggle-icon"
                            />
                        </div>
                    </div>
                    <div className="rfp">
                        <div className="check">
                            <label htmlFor="reme">Remember Me</label>
                            <input id="reme" type="checkbox"/>
                        </div>
                        <nav>
                            <NavLink to='/Forgot'>Forgot Password?</NavLink>
                        </nav>
                    </div>
                    
                    <div className="links">
                        <button className="submit" type="submit">Log In</button>
                    </div>
                </form>
                <hr />
                <div className="oAuth">
                    <GoogleLogin 
                        onSuccess={responseMessage} 
                        onError={errorMessage}
                        cookiePolicy={'single_host_origin'} 
                    />
                </div>
            </div>
        </div>
    );
}
