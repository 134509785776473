import React from "react";
import { NavLink } from "react-router-dom";
import dashboard from "../images/dashboard.png"
import details from "../images/details.png"
import operations from "../images/operations.png"

export default  function Sidebar(){
    return(
        <div className="s-main">
            <div className="s-links">
                <ul>
                    <div className="s-link">
                        <img src={dashboard} alt="" />
                        <NavLink to='/Dashboard'><li>Dashboard</li></NavLink>
                    </div>
                    <div className="s-link">
                        <img src={details}/>
                        <NavLink to='/TaskDetails'><li>Task Details</li></NavLink>
                    </div>
                    <div className="s-link">
                        <img src={operations} alt="" />
                        <NavLink to='/Operations'><li>Operations</li></NavLink>
                    </div>
                </ul>
            </div>
        </div>
    )
}