import React, { useState, useEffect } from "react";
import { LineChart } from '@mui/x-charts/LineChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

export default function Operations(props) {
  const [selectedYear, setSelectedYear] = useState("2024");
  const [hoursData, setHoursData] = useState([]);

   const removeQuotesFromStrings = (jsonStr) => {
        if (jsonStr != null) {
            return jsonStr.replace(/"(.*?)"/g, (match, p1) => {
                return p1.includes(':') ? `"${p1}"` : p1;
            });
        }
        return null;
    };

    const email = removeQuotesFromStrings(localStorage.getItem('email'));
    const name = removeQuotesFromStrings(localStorage.getItem('name'));



  const monthNames = [
    'January 2024', 'February 2024', 'March 2024', 'April 2024', 'May 2024',
    'June 2024', 'July 2024', 'August 2024', 'September 2024', 'October 2024',
    'November 2024', 'December 2024'
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://timesheet.backend.webwic.com/get_hours", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ year: selectedYear, email, name })
        });
        const data = await response.json();
        console.log(data);
        if (data.status === "success") {
          const formattedData = Object.entries(data.data).map(([month, value]) => ({
            month: monthNames.indexOf(month),
            value: isNaN(value) ? 0 : value
          }));
          console.log(formattedData);
          setHoursData(formattedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedYear, email, name]);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const xAxisData = hoursData.map(item => item.month);
  const seriesData = hoursData.map(item => item.value);

  console.log("xAxisData:", xAxisData);
  console.log("seriesData:", seriesData);

  return (
    <div className="h-container">
      <Navbar />
      <div className='h-main'>
        {!props.small && <div className="h-side">
          <Sidebar />
        </div>}
        <div className='h-d'>
          <div className="o-main">
            <h2>Monthly Work Hours Overview</h2>
            <div>

              <div className="only">
                <label htmlFor="year-select">Select Year: </label>
                <select id="year-select" value={selectedYear} onChange={handleYearChange}>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                </select>
              </div>

            </div>
            <div className="chart">
              <LineChart
                className="graph"
                xAxis={[{
                  data: xAxisData,
                  label: 'Month',
                  valueFormatter: (value) => monthNames[value] || '',
                  labelAngle: -45, // Tilt the labels

                  labelAnchor: 'end', // Align the labels to the end of the tick
                  

                }]}
                series={[{
                  data: seriesData,
                  label: 'Hours Worked'
                }]}

                width={1000}
                height={500}

              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
