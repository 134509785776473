import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { BarChart } from '@mui/x-charts/BarChart';

import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import axios from 'axios';

export default function TaskDetails(props) {
    const [selChart, setSelChart] = useState(true);
    const [datasetB, setDatasetB] = useState([]);
    const [selectedYear, setSelectedYear] = useState(2024);
    const [lineChartData, setLineChartData] = useState([]);
    const [selectedMonthYear, setSelectedMonthYear] = useState(`${new Date().toLocaleString('default', { month: 'short' })}-${new Date().getFullYear()}`);

    const removeQuotesFromStrings = (jsonStr) => {
        if (jsonStr != null) {
            return jsonStr.replace(/"(.*?)"/g, (match, p1) => {
                return p1.includes(':') ? `"${p1}"` : p1;
            });
        }
        return null;
    };

    const email = removeQuotesFromStrings(localStorage.getItem('email'));
    const name = removeQuotesFromStrings(localStorage.getItem('name'));

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        fetchTaskData(selectedYear);
    }, [selectedYear]);

    useEffect(() => {
        fetchHoursData(selectedMonthYear);
    }, [selectedMonthYear]);

    const fetchTaskData = async (year) => {
        try {
            const response = await axios.get('https://timesheet.backend.webwic.com/get_tasks_by_status', {
                params: { name, email, year }
            });

            const data = response.data;
            const formattedData = Object.keys(data).map((month) => ({
                month: month.substring(0, 3),
                todo: data[month]['to-do'],
                doing: data[month]['doing'],
                done: data[month]['done']
            }));
            setDatasetB(formattedData);
            console.log("Combo : ",formattedData)
        } catch (error) {
            console.error("Error fetching task data:", error);
        }
    };

    const fetchHoursData = async (monthYear) => {
      const [shortMonth, year] = monthYear.split('-');
      const month = shortMonths.indexOf(shortMonth) + 1;
  
      try {
          const response = await axios.post('https://timesheet.backend.webwic.com/get_hours_daywise', {
              name,
              email,
              month,
              year
          });
  
          const data = response.data.data;
          console.log("Fetched data:", data);
  
          const formattedData = Object.keys(data).map(date => ({
              date,
              hours: data[date]
          }));
  
          console.log("Formatted Line Chart Data:", formattedData);
          setLineChartData(formattedData);
      } catch (error) {
          console.error("Error fetching hours data:", error);
      }
  };
  


    const chartSetting = {
        yAxis: [
            {
                label: 'Tasks',
            },
        ],
        width: 1000,
        height: 400,
        sx: {
            [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translate(-30px, 0)',
            },
        },
    };

    const handleMonthYearSelection = (e) => {
        setSelectedMonthYear(e.target.value);
    };

    const handleYearSelection = (e) => {
        setSelectedYear(e.target.value);
    };

    return (
        <div className="h-container">
            <Navbar />
            <div className='h-main'>
                {!props.small && <div className="h-side">
                    <Sidebar />
                </div>}
                <div className='h-d'>
                    <div className="t-main">
                        <div className="t-btns">
                            <button className="sel-btn" onClick={() => setSelChart(true)}>Monthly Task Overview</button>

                            <button className="sel-btn" onClick={() => setSelChart(false)}>Hours Worked {selectedMonthYear}</button>

                        </div>
                        {selChart &&
                            <div className="chart">
                                <h2>Monthly Task Overview</h2>
                                <div>

                                  <div className="select">

                                    <label htmlFor="year-select">Select Year: </label>
                                    <select id="year-select" value={selectedYear} onChange={handleYearSelection}>
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                    </select>

                                  </div>

                                </div>
                                <div className="only">
                                    <BarChart
                                        className="graph"
                                        dataset={datasetB}
                                        xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                                        series={[
                                            { dataKey: 'todo', label: 'To-Do' },
                                            { dataKey: 'doing', label: 'Doing' },
                                            { dataKey: 'done', label: 'Done' },
                                        ]}
                                        {...chartSetting}
                                    />
                                </div>
                            </div>
                        }

                        {!selChart &&
                            <div className="chart">
                                <h2>Hours Worked in {selectedMonthYear}</h2>
                                <div>
                                  <div className="select">

                                    <label htmlFor="month-year-select">Select Month-Year: </label>
                                    <select id="month-year-select" value={selectedMonthYear} onChange={handleMonthYearSelection}>
                                        {months.map((month, index) => {
                                            return (
                                                <option key={index} value={`${shortMonths[index]}-${selectedYear}`}>
                                                    {shortMonths[index]}-{selectedYear}
                                                </option>
                                            )
                                        })}
                                    </select>

                                  </div>
                                </div>
                                <div className="only">
                                    <ResponsiveContainer width="100%" height={400} className="repsonsiveContainer">
                                        <LineChart data={lineChartData}>
                                            <XAxis 
                                              dataKey="date" 
                                              angle={-60}
                                              fontSize={10}
                                              textAnchor="end"
                                              tickMargin={-0}
                                            />
                                            <YAxis />
                                            <Tooltip />
                                            <Line type="monotone" dataKey="hours" />

                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
