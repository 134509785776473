import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import axios from "axios";

export default function Dashboard(props) {
    const [data, setData] = useState([]);

    const [editIndex, setEditIndex] = useState(null);
    const [editData, setEditData] = useState({
        uDate: '',
        uDescription: '',
        uStatus: '',
        uStatusDescription: '',
        uHours: 0
    });


    const email = localStorage.getItem('email')?.replace(/"(.*?)"/g, '$1');
    const name = localStorage.getItem('name')?.replace(/"(.*?)"/g, '$1');

    const [totalWorkDays, setTotalWorkDays] = useState('0');
    const [ongoingTasks, setOngoingTasks] = useState('0');
    const [completedTasks, setCompletedTasks] = useState('0');
    const [totalWorkHours, setTotalWorkHours] = useState('0');

    const fetchData = () => {
        axios.get(`https://timesheet.backend.webwic.com/get_tasks?name=${name}&email=${email}`)
            .then(res => {
                setData(res.data.tasks);
                setCompletedTasks(res.data.count_of_tasks_done);
                setOngoingTasks(res.data.count_of_tasks_ongoing);
                setTotalWorkDays(res.data.total_days_worked_in_month);
                setTotalWorkHours(res.data.total_hours_worked_in_month);
            })
            .catch(er => console.log(er));
    };

    useEffect(() => {
        fetchData();
    }, [name, email]);


    const handleEdit = (index, task) => {
        setEditData({
            uDate: task.date,
            uDescription: task.task_description,
            uStatus: task.task_status,
            uStatusDescription: task.task_status_description,
            uHours: task.hours_worked
        });
        setEditIndex(index);
    };

    const handleUpdate = () => {
        const updatedTask = {
            name: name,
            email: email,
            date: editData.uDate, // Include the original date (not editable)
            task_description: editData.uDescription,
            task_status: editData.uStatus,
            task_status_description: editData.uStatusDescription,
            hours_worked: editData.uHours
        };

        axios.put(`https://timesheet.backend.webwic.com/update_task`, updatedTask)
            .then(res => {
                setEditIndex(null); // Exit edit mode
                fetchData(); // Refetch data to reload the dashboard
            })
            .catch(er => console.log(er));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCancel = () => {
        setEditIndex(null); // Exit edit mode without saving changes


    };

    return (
        <div className="h-container">
            <Navbar />
            <div className='h-main'>
                {!props.small &&
                    <div className="h-side">
                        <Sidebar />
                    </div>
                }
                <div className='h-d'>
                    <div className="d-main">
                        <div className="d-board">
                            <div className="d-count">
                                <div className="dc">
                                    <h4>Total Working Days: <br /> {totalWorkDays}</h4>
                                </div>
                                <div className="dc">
                                    <h4>Ongoing Tasks : <br /> {ongoingTasks}</h4>
                                </div>
                                <div className="dc">
                                    <h4>Completed Tasks : <br /> {completedTasks}</h4>
                                </div>
                                <div className="dc">
                                    <h4>Total Working Hours : <br /> {totalWorkHours}</h4>
                                </div>
                            </div>
                            <div className="addc">
                                <NavLink to='/Form'>
                                    <button className="add">
                                        <h3>New Task</h3>
                                    </button>
                                </NavLink>
                            </div>
                            <div className="d-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Task Description</th>
                                            <th>Task Status</th>
                                            <th>Task Status Description</th>
                                            <th>Hours</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {

                                            data.map((task, index) => (
                                                <tr key={index}>
                                                    {editIndex === index ? (

                                                        <>
                                                            <td>
                                                                <input
                                                                    type="date"

                                                                    value={editData.uDate}
                                                                    name="uDate"
                                                                    onChange={handleChange}

                                                                    disabled // Date should not be editable
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"

                                                                    value={editData.uDescription}
                                                                    name="uDescription"
                                                                    onChange={handleChange}
                                                  />
                                                            </td>
                                                            <td>
                                                                <select

                                                                    value={editData.uStatus}
                                                                    name="uStatus"
                                                                    onChange={handleChange}

                                                                >
                                                                    <option value="To-Do">To-Do</option>
                                                                    <option value="Done">Done</option>
                                                                    <option value="Doing">Doing</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"

                                                                    value={editData.uStatusDescription}
                                                                    name="uStatusDescription"
                                                                    onChange={handleChange}

                                                                />
                                                            </td>
                                                            <td>
                                                                <select

                                                                    value={editData.uHours}
                                                                    name="uHours"
                                                                    onChange={handleChange}

                                                                >
                                                                    <option value={1}>1</option>
                                                                    <option value={2}>2</option>
                                                                    <option value={3}>3</option>
                                                                    <option value={4}>4</option>
                                                                    <option value={5}>5</option>
                                                                    <option value={6}>6</option>
                                                                </select>
                                                            </td>

                                                            <td>
                                                                <button onClick={handleUpdate}>Update</button>
                                                                <button onClick={handleCancel}>Cancel</button>
                                                            </td>

                                                        </>
                                                    ) : (
                                                        <>
                                                            <td>{task.date}</td>
                                                            <td className="truncated-text">{task.task_description}</td>
                                                            <td>{task.task_status}</td>
                                                            <td className="truncated-text">{task.task_status_description}</td>
                                                            <td>{task.hours_worked}</td>
                                                            <td>

                                                                <button onClick={() => handleEdit(index, task)}>Edit</button>

                                                            </td>
                                                        </>
                                                    )}
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
