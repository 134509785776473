import React from "react";
import logo from "../images/logo.png"
import { GoogleLogin } from "@react-oauth/google";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { NavLink } from "react-router-dom";


export default function Forgot(){
    const responseMessage = (response) => {
        console.log(response);
    };
    const errorMessage = (error) => {
        console.log(error);
    };
    return(
        <div className="f-main">
            <div className="logo">
                <NavLink to = '/'><img src={logo} alt="" /></NavLink>
            </div>
            <div className="f-container-light">
                <h3>Reset your password</h3>
                <p>Enter your email address and we'll send you a link to reset your password</p>
                <form id="f-form">
                    <div className="input">
                        <input type="email" placeholder="Enter email address" required/>
                    </div>
                    <button className="submit" type="submit">Send Login Link</button>
                    <hr />
                </form>
                <div className="oAuth">
                    <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
                </div>
                <div className="back">
                    <FontAwesomeIcon icon={faChevronLeft} />
                    <NavLink to ='/' ><h4>Back to Login</h4></NavLink>
                </div>
            </div>
        </div>
    )
}