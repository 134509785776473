import React, { useEffect} from 'react';
import './App.css';
import LoginPage from './components/LoginPage';
import Forgot from './components/Forgot'
import { Route, Routes } from 'react-router-dom';
import FormPage from './components/FormPage';
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import TaskDetails from './components/TaskDetails';
import Operations from './components/Operations';
import Navbar from './components/Navbar';
import ProtectedRoutes from './utils/ProtectedRoutes';

export const User = React.createContext();

function App() {

  const [user, setUser] = React.useState(false)

  const[small, setSmall] = React.useState(window.innerWidth <= 1090)

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth
      // const height = window.innerHeight
      setSmall(width <= 1090)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }      
  }, [])



  return (
    <div className="App">
      <User.Provider value={[user, setUser]}>
      <Routes>
        <Route element={<LoginPage/>} path='/'/>
        <Route element={<Forgot/>} path='/Forgot' />
        <Route element={<ProtectedRoutes/>}>
          <Route element={<Dashboard small={small} /> } path="/Dashboard"/>
          <Route element={<FormPage small={small}/>} path="/Form"/>
          <Route element={<Sidebar/>} path="/Sidebar"/>
          <Route element={<Navbar/> } path="/Navbar"/>
          <Route element={<TaskDetails small={small} /> } path="/TaskDetails"/>
          <Route element={<Operations small={small}/>} path="/Operations"/>
        </Route>
      </Routes>
      </User.Provider>
    </div>
  );
}

export default App;
